


















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {ContractHistoryEntry} from '@/employee/contractHistory/store/ContractHistoryEntry';
import {getContractHistory} from '@/employee/contractHistory/store/endpoints';
import {formatDate} from '@/shared/date/formatDate';
import HashId from '@/shared/HashId.vue';
import {ID} from '@/shared/store/AbstractEntity';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';

@Component({
    components: {
        HmSimpleTable,
        HashId,
    },
    filters: {
        formatDate,
    },
})
export default class ContractHistory extends Mixins(ApiCall) {
    @Prop(Number)
    id!: ID;

    contractHistory: ContractHistoryEntry[] = [];

    get headers(): string[] {
        return [
            this.$t('contractHistoryContract') as string,
            this.$t('contractHistoryStartDate') as string,
            this.$t('contractHistoryEndDate') as string,
            this.$t('contractHistoryWorkingTime') as string,
            this.$t('contractHistoryTimesheet') as string,
            this.$t('contractHistoryDetails') as string,
        ];
    }

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.contractHistory = await getContractHistory(this.id);
    }
}
