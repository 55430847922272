import {ID} from '@/shared/store/AbstractEntity';
import {api} from '@/shared/api';
import {forClient} from '@/shared/date/convertDate';
import {Consent, ConsentType} from '@/employee/consents/store/Consent';

export async function getHistory(employeeId: ID, consentType: ConsentType): Promise<Consent[]> {
    const result = await api<Consent<string>[]>({
        method: 'get',
        url: `consents/${employeeId}/${consentType}/consent-history`,
    });

    return result.data.map((consent) => {
        return {
            consent: consent.consent,
            date: forClient(consent.date),
            changedBy: consent.changedBy,
        };
    });
}

export async function changeConsent(employeeId: ID, consentType: ConsentType, consent: boolean): Promise<null> {
    await api<Consent<string>[]>({
        method: 'post',
        url: `consents/${employeeId}/${consentType}`,
        headers: {
            'content-type': 'application/json',
        },
        data: JSON.stringify(consent),
    });

    return null;
}
