import {api} from '@/shared/api';
import {ContractHistoryEntry} from '@/employee/contractHistory/store/ContractHistoryEntry';
import {ID} from '@/shared/store/AbstractEntity';

export async function getContractHistory(id: ID): Promise<ContractHistoryEntry[]> {
    const {data} = await api<ContractHistoryEntry<string>[]>({
        method: 'get',
        url: `Employees/contractHistory/${id}`,
    });

    return data.map((entry) => {
        return {
            ...entry,
            startDate: new Date(entry.startDate),
            endDate: entry.endDate ? new Date(entry.endDate) : null,
        };
    });
}
