




























import {Component, Prop, Vue} from 'vue-property-decorator';
import StatNumber from '@/shared/StatNumber.vue';
import {
    SelfDevelopmentData,
    SelfDevelopmentRequestStatus,
} from '@/employee/selfDevelopment/store/SelfDevelopmentRequest';
import {formatCurrency} from '@/shared/formatCurrency';

@Component({
    components: {
        StatNumber,
    },
    filters: {
        formatCurrency,
    },
})
export default class SelfDevelopmentCurrentYear extends Vue {
    @Prop(Object)
    currentYear!: SelfDevelopmentData;

    get year(): number {
        return this.currentYear.year;
    }

    get budget(): number {
        return this.currentYear.budget;
    }

    get used(): number {
        return this.currentYear.budgetUsed;
    }

    get planned(): number {
        return this.currentYear.requests.reduce((acc, request) => {
            if (request.approvalStatus === SelfDevelopmentRequestStatus.Approved ||
                request.approvalStatus === SelfDevelopmentRequestStatus.Pending ||
                request.approvalStatus === SelfDevelopmentRequestStatus.PreApproved) {
                if (request.cost) {
                    acc += request.cost;
                }
            }
            if (request.approvalStatus === SelfDevelopmentRequestStatus.Closed) {
                if (request.realCost) {
                    acc += request.realCost;
                }
            }

            return acc;
        }, 0);
    }

    get left(): number {
        return this.currentYear.budget - this.planned;
    }
}
