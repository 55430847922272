



















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ApiCall } from '@/shared/mixins/ApiCall';
import AppDialog from '@/shared/AppDialog.vue';
import { addInsuredRelative, deleteInsuredRelative, getMyInsuredRelatives, updateInsuredRelative } from './store/endpoints';
import { ID } from '@/shared/store/AbstractEntity';
import { InsuredRelative, InsuredRelativeAddRequest, InsuredRelativeEditRequest, isValidInsuredRelative } from './store/InsuredRelative';
import { Nullable } from '@/shared/types/Nullable';
import InsuredRelativeForm from '@/employee/employeeZus/InsuredRelativeForm.vue';
import InsuredRelativesList from '@/employee/employeeZus/InsuredRelativesList.vue';

@Component({
    components: {
        InsuredRelativeForm,
        InsuredRelativesList,
        AppDialog,
    },
})
export default class InsuredRelatives extends Mixins(ApiCall){
    @Prop(Number)
    employeeId!: ID;

    insuredRelatives: InsuredRelative[] = [];
    recordToDelete: Nullable<InsuredRelative> = null;
    recordToEdit: Nullable<InsuredRelative> = null;

    async mounted(): Promise<void> {
        await this.fetchList();
    }
    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            this.insuredRelatives = await getMyInsuredRelatives(this.employeeId);
        });
    }

    get isValidForm(): boolean {
        if (!this.recordToEdit) {
            return false;
        }
        return isValidInsuredRelative(this.recordToEdit);
    }

    get deleteRecordConfirmBody(): string {
        if (!this.recordToDelete) {
            return '';
        }

        return this.$t('insuredRelativeConfirmDeleteBody', this.recordToDelete) as string;
    }

    onDelete(insuredRelative: InsuredRelative): void {
        this.recordToDelete = insuredRelative;
    }

    onEdit(insuredRelative: InsuredRelative): void {
        this.recordToEdit = insuredRelative;
    }

    async onSaveConfirm(recordToEdit: InsuredRelativeEditRequest): Promise<void> {
        const index = this.insuredRelatives.findIndex((x) => x.id === recordToEdit.id);
        if (index === -1) {
            return;
        }

        const formData = this.createFormData(recordToEdit);

        await this.apiCall(async () => {
            await updateInsuredRelative(this.employeeId, recordToEdit.id, formData);
        });

        this.recordToEdit = null;
        await this.fetchList();
    }

    async onCreateConfirm(recordToAdd: InsuredRelativeAddRequest): Promise<void> {
        const formData = this.createFormData(recordToAdd);
        await this.apiCall(async () => {
            await addInsuredRelative(this.employeeId, formData);
        });

        this.recordToEdit = null;

        await this.fetchList();
    }

    async onDeleteConfirm(): Promise<void> {
        const index = this.insuredRelatives.findIndex((x) => x.id === this.recordToDelete?.id);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await deleteInsuredRelative(this.employeeId, this.recordToDelete?.id as number);
        });

        this.recordToDelete = null;

        await this.fetchList();
    }

    createFormData<Type>(record: Type): FormData{
        const formData = new FormData();
        formData.append('employeeId', this.employeeId.toString());

        for (const [key, value] of Object.entries(record)) {
            if(key == 'dateOfBirth'){
                formData.append(key, new Date(value as string).toDateString());
                continue;
            }
            formData.append(key, value?.toString() || '');
        }
        return formData;
    }

}
