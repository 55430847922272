import {ID} from '@/shared/store/AbstractEntity';
import {EmergencyContact, EmergencyContactAdd} from '@/employee/emergencyContact/store/EmergencyContact';
import {api} from '@/shared/api';

export async function listEmergencyContacts(employeeId: ID): Promise<EmergencyContact[]> {
    const {data} = await api<EmergencyContact[]>({
        method: 'get',
        url: `Employees/${employeeId}/emergencyContacts`,
    });

    return data;
}

export async function createEmergencyContact(employeeId: ID, emergencyContact: EmergencyContactAdd): Promise<EmergencyContact> {
    const {data} = await api<EmergencyContact>({
        method: 'post',
        url: `Employees/${employeeId}/emergencyContacts`,
        data: emergencyContact,
    });

    return data;
}

export async function deleteEmergencyContact(employeeId: ID, emergencyContact: EmergencyContact): Promise<void> {
    await api<EmergencyContact>({
        method: 'delete',
        url: `Employees/${employeeId}/emergencyContacts/${emergencyContact.id}`,
    });
}

export async function updateEmergencyContact(employeeId: ID, emergencyContact: EmergencyContact): Promise<EmergencyContact> {
    const {data} = await api<EmergencyContact>({
        method: 'put',
        url: `Employees/${employeeId}/emergencyContacts`,
        data: emergencyContact,
    });

    return data;
}
