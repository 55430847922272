import {TaxType, TaxStatus, TaxesResult} from '@/employee/taxes/store/Taxes';
import {api} from '@/shared/api';
import {ID} from '@/shared/store/AbstractEntity';

export async function getTaxesResult(employeeId: ID): Promise<TaxesResult> {
    const result = await api<TaxesResult>({
        method: 'get',
        url: `Employees/${employeeId}/taxes`,
    });

    return result.data;
}

export async function setTaxStatus(employeeId: ID, taxesType: TaxType, taxStatus: TaxStatus): Promise<TaxStatus> {
    const result = await api<TaxStatus>({
        method: 'post',
        url: `Employees/${employeeId}/taxes/${taxesType}`,
        headers: {
            'content-type': 'application/json',
        },
        data: JSON.stringify(taxStatus),
    });

    return result.data;
}

export async function setTaxOffice(employeeId: ID, taxOfficeId: number): Promise<boolean> {
    const result = await api<boolean>({
        method: 'post',
        url: `Employees/${employeeId}/taxes/taxOffice`,
        headers: {
            'content-type': 'application/json',
        },
        data: JSON.stringify(taxOfficeId),
    });

    return result.data;
}
