import { Nullable } from '@/shared/types/Nullable';

export interface ZusInformationResponse {
    employeeId: number;
    nfzUnitCode: string;
    retirementGranted: boolean;
    isHandicapped: boolean;
    handicapLevelId: ZusInfoHandicapLevel;
    statementReadableFileName: string;
    statementFileUri: string;
}

export interface ZusInformationRequest {
    employeeId: number;
    nfzUnitCode: string;
    retirementGranted: boolean;
    isHandicapped: boolean;
    handicapLevelId: Nullable<ZusInfoHandicapLevel>;
}

export enum ZusInfoHandicapLevel {
    Lekki = 1,
    Umiarkowany = 2,
    Znaczny = 3,
}