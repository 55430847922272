








import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import SingleConsent from '@/employee/consents/SingleConsent.vue';
import {ConsentType} from '@/employee/consents/store/Consent';
import {ID} from '@/shared/store/AbstractEntity';

@Component({
    components: {
        SingleConsent,
    },
})
export default class ConsentsForm extends Mixins(ApiCall) {
    @Prop(Number)
    employeeId!: ID;

    ConsentType = ConsentType;
}
