











import {Component, Prop, Vue} from 'vue-property-decorator';
import DictionaryDropdown from '@/shared/DictionaryDropdown.vue';
import {ID} from '@/shared/store/AbstractEntity';

@Component({
    components: {
        DictionaryDropdown,
    },
})
export default class ContractTypeWidget extends Vue {
    @Prop(Number) value!: ID;
    @Prop(Boolean) disabled!: boolean;
    @Prop({type: String, default: ''}) name!: string;
}
