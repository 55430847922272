













































import {Component, Mixins, Prop} from 'vue-property-decorator';
import AppDialog from '@/shared/AppDialog.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {formatDate} from '@/shared/date/formatDate';
import {Nullable} from '@/shared/types/Nullable';
import {InjectShowMessage} from '@/shared/mixins/InjectShowMessage';
import {BenefitEntry} from '@/employee/benefits/store/BenefitEntry';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import SingleConsent from '@/employee/consents/SingleConsent.vue';
import {ConsentType} from '@/employee/consents/store/Consent';
import HmCol from '@/shared/HmCol.vue';
import {isValidBenefitsRequest, BenefitsRequest} from '@/employee/benefits/store/BenefitsRequest';
import {getBenefits, sendBenefitsRequestForm} from '@/employee/benefits/store/endpoints';
import BenefitsRequestForm from './BenefitsRequestForm.vue';
import {formatCurrency} from '@/shared/formatCurrency';

@Component({
    components: {
        HmCol,
        SingleConsent,
        HmSimpleTable,
        AppDialog,
        BenefitsRequestForm,
    },
    filters: {
        formatDate,
        formatCurrency,
    },
})
export default class BenefitsView extends Mixins(ApiCall, InjectShowMessage) {
    @Prop(Number)
    id!: number;

    allBenefits: BenefitEntry[] = [];

    requestForm: Nullable<BenefitsRequest> = null;

    ConsentType = ConsentType;
    showExpired = false;

    formatDate: any;

    get visibleBenefits(): BenefitEntry[] {
        if (this.showExpired) {
            return this.allBenefits;
        } else {
            const now = new Date();
            return this.allBenefits.filter((benefitEntry) => {
                return benefitEntry.endDate === null || benefitEntry.endDate > now;
            });
        }

    }

    get headers(): string[] {
        return [
            this.$t('benefitsViewType') as string,
            this.$t('benefitsViewStartDate') as string,
            this.$t('benefitsViewEndDate') as string,
            this.$t('benefitsViewEmployeeCost') as string,
            this.$t('benefitsViewCompanyCost') as string,
            this.$t('benefitsViewVat') as string,
        ];
    }

    mounted(): void {
        this.fetchData();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.allBenefits = await getBenefits(this.id);
        });
    }

    async onCreateConfirm(requestForm: BenefitsRequest): Promise<void> {
        await this.apiCall(async () => {
            const response = await sendBenefitsRequestForm(this.id, requestForm);

            if (response === 202) {
                this.showMessage(this.$t('RequestSendSuccessFully'), 'success');
            }
        });
        this.requestForm = null;

        await this.fetchData();
    }

    get isBenfitsFormValid(): boolean {
        if (!this.requestForm) {
            return false;
        }

        return isValidBenefitsRequest(this.requestForm);
    }
}
