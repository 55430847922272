




































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ApiCall } from '@/shared/mixins/ApiCall';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import { InjectShowMessage } from '@/shared/mixins/InjectShowMessage';
import { ID } from '@/shared/store/AbstractEntity';
import HmCol from '@/shared/HmCol.vue';
import {TaxesResult, TaxOffice, Voivodeship} from '@/employee/taxes/store/Taxes';
import {Nullable} from '@/shared/types/Nullable';
import {setTaxOffice} from '@/employee/taxes/store/endpoints';

@Component({
    components: {
        HmCol,
    },
})

export default class TaxOfficeForm extends Mixins(ApiCall, InputAttrs, InjectShowMessage) {

    @Prop(Number)
    employeeId!: ID;

    @Prop(Object)
    taxesResult!: TaxesResult;

    voivodeships: Voivodeship[] = [];
    chosenVoivodeshipId: Nullable<number> = null;

    taxOffices: TaxOffice[] = [];
    chosenTaxOfficeId: Nullable<number> = null;

    success = false;

    async mounted(): Promise<void> {
        this.loadData();
    }

    loadData(): void{
        const fetchedTaxOfficeId = this.taxesResult.taxOfficeId;
        const fetchedVoivodeships = this.taxesResult.voivodeships;
        const fetchedTaxOffices = this.taxesResult.taxOffices;

        this.voivodeships = fetchedVoivodeships;
        this.taxOffices = fetchedTaxOffices;

        const chosenTaxOffice =  this.taxOffices.find(x => x.id == fetchedTaxOfficeId);
        if (chosenTaxOffice){
            this.chosenTaxOfficeId = chosenTaxOffice.id ;
            this.chosenVoivodeshipId = this.voivodeships.filter(x => x.id == chosenTaxOffice.voivodeshipId ).length > 0 ? chosenTaxOffice.voivodeshipId : null;
        }
    }

    async onSubmit(): Promise<void> {
        if(this.chosenTaxOfficeId){
            await this.apiCall(async () => {
                await setTaxOffice(this.employeeId, this.chosenTaxOfficeId ?? 0);
            });

            this.success = true;

            setTimeout(() => {
                this.success = false;
            }, 3000);

            this.$emit('reloadTaxesResult', () =>  this.loadData());
        }
    }
}
