






















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import { SelfDevRequestAddModel, eventTypes, typesOfSkills, locationOptions } from './store/SelfDevelopmentRequest';
import DatePicker from '@/shared/date/DatePicker.vue';
import { Nullable } from '@/shared/types/Nullable';
import AppDialog from '@/shared/AppDialog.vue';
import { Validatable } from '@/shared/types/Validatable';

type ListItem = {
    name: string;
    value: string;
}

@Component({
    components: {
        DatePicker,
        AppDialog,
    },
})
export default class SelfDevelopmentRequestForm extends Vue {

    @Prop(Boolean)
    value!: boolean;

    eventType: ListItem[] = [];
    typeOfSkillsDeveloped = '';
    eventName = '';
    eventOrganizerName = '';
    eventOrganizerWebsite = '';
    eventOrganizerAddress = '';
    locationType = '';
    stationaryLocation = '';
    from: Nullable<Date> = null;
    to: Nullable<Date> = null;
    cost: Nullable<number> = null;
    costBreakdown = '';
    goal = '';
    eventDuration = '';

    validStartDate = true;
    disabledSubmit = false;

    get eventTypes(): ListItem[] {
        return eventTypes.map(x => ({name: x, value: x }));
    }

    get typesOfSkills(): string[] {
        return typesOfSkills;
    }

    get locationOptions(): ListItem[] {
        return locationOptions.map(x => ({name: x, value: x }));
    }

    mandatoryFieldRule(value: string): boolean | string  {
        return !!value || this.$t('requiredField') as string;
    }

    mandatoryListFieldRule(values: [] ): boolean | string  {
        if (values.length == 0) {
            return this.$t('requiredField') as string;
        }
        return true;
    }

    submit(): void {
        const form: SelfDevRequestAddModel = {
            eventType: this.eventType.map(x => x.value).join(','),
            typeOfSkillsDeveloped: this.typeOfSkillsDeveloped,
            eventName: this.eventName,
            eventOrganizerName: this.eventOrganizerName,
            eventOrganizerWebsite: this.eventOrganizerWebsite,
            eventOrganizerAddress: this.eventOrganizerAddress,
            locationType: this.locationType,
            stationaryLocation: this.stationaryLocation,
            startDate: this.from ?? new Date(),
            endDate: this.to,
            cost: this.cost ?? 0,
            costBreakdown: this.costBreakdown,
            goal: this.goal,
            eventDuration: this.eventDuration,
        };

        const validForm = (this.$refs.form as Vue & Validatable).validate();
        const validStartDate = this.validateStartDate();
        if (!this.disabledSubmit && validForm && validStartDate) {
            this.disabledSubmit = true;

            this.$emit('submitForm', form);
        }
    }

    validateStartDate(): boolean {
        const startDateValid = !!this.from;
        this.validStartDate = startDateValid;
        return startDateValid;
    }
}
