

















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AbstractEntity, ID} from './store/AbstractEntity';
import {Dictionaries} from '@/dictionaries/store';
import {DictionaryWidget} from '@/shared/mixins/DictionaryWidget';

@Component
export default class DictionaryDropdown extends Mixins(DictionaryWidget) {
    @Prop(Number)
    protected value!: ID | ID[];

    @Prop(String)
    protected label!: string;

    @Prop({type: String, default: ''})
    protected name!: string;

    @Prop(String)
    protected valueKey!: keyof Dictionaries;

    @Prop(Boolean)
    protected multiple!: boolean;

    @Prop({type: [String, Function], default: 'name'})
    protected itemText!: string;

    @Prop(Boolean) disabled!: boolean;

    protected get items(): AbstractEntity[] {
        return this.$store.state.dictionaries[this.valueKey];
    }

    protected get selectValue(): ID | null | ID[] {
        if (Array.isArray(this.value)) {
            return this.value || [];
        } else {
            return this.value || null;
        }
    }

    private onChange(id: ID | ID[]): void {
        this.$emit('input', id);
    }
}
