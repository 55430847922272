

























import { Component, Prop, Vue } from 'vue-property-decorator';
import MyZusInformation from '@/employee/employeeZus/MyZusInformation.vue';
import InsuredRelatives from '@/employee/employeeZus/InsuredRelatives.vue';
import { ID } from '@/shared/store/AbstractEntity';

@Component({
    components: {
        MyZusInformation,
        InsuredRelatives,
    },
})

export default class EmployeeZusScreen extends Vue {
    @Prop(Number)
    employeeId!: ID;
}
