























import {Component, Prop, Vue} from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {EmergencyContact} from '@/employee/emergencyContact/store/EmergencyContact';
import {TranslateResult} from 'vue-i18n';

@Component({
    components: {
        HmSimpleTable,
    },
})
export default class EmergencyContactList extends Vue {
    @Prop(Array)
    list!: EmergencyContact;

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    readOnly!: boolean;

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('firstName'),
            this.$t('lastName'),
            this.$t('phone'),
            '',
        ];
    }
}
