

























import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import {BenefitsRequest} from './store/BenefitsRequest';
import DatePicker from '@/shared/date/DatePicker.vue';
import {Nullable} from '@/shared/types/Nullable';
import {dateToDayStart} from '@/shared/date/dateToDayStart';
import {InputAttrs} from '@/shared/mixins/InputAttrs';

@Component({
    components: {
        DatePicker,
    },
})

export default class BenefitsRequestForm extends Mixins(InputAttrs) {
    @Prop(Object)
    value!: BenefitsRequest;

    protected startDate: Date = dateToDayStart();
    protected endDate: Nullable<Date> = null;

    ruleDateMandatory(value: Nullable<Date>): string | boolean {
        return value === null ? this.$t('dateIsMandatory') as string : true;
    }

    ruleEndDateInFuture(value: Date): string | boolean {
        return value <= this.startDate ? this.$t('startDateExceedsEndDate') as string : true;
    }

    onInput(field: keyof BenefitsRequest, value: BenefitsRequest[keyof BenefitsRequest]): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }
}
