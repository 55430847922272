




import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class GenderDropdown extends Vue {
    @Prop(String)
    value!: string;

    get items(): object[] {
        return [
            {
                text: this.$t('genderMale'),
                value: 'Male',
            },
            {
                text: this.$t('genderFemale'),
                value: 'Female',
            },
        ];
    }

    onInput(value: string): void {
        this.$emit('input', value);
    }
}
