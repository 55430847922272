export class TaxesResult {
    pit2Status: TaxStatus;
    midClassStatus: TaxStatus;
    revenueCostStatus: TaxStatus;
    taxRateStatus: TaxStatus;
    taxOfficeId: number;
    taxOffices: TaxOffice[];
    voivodeships: Voivodeship[];

    constructor(
        pit2StatusVal: TaxStatus,
        midClassStatusVal: TaxStatus,
        revenueCostStatusVal: TaxStatus,
        taxRateStatusVal: TaxStatus,
        taxOfficeIdVal: number,
        taxOfficesVal: TaxOffice[],
        voivodeshipsVal: Voivodeship[]) {
        this.pit2Status = pit2StatusVal;
        this.midClassStatus = midClassStatusVal;
        this.revenueCostStatus = revenueCostStatusVal;
        this.taxRateStatus = taxRateStatusVal;
        this.taxOfficeId = taxOfficeIdVal;
        this.taxOffices = taxOfficesVal;
        this.voivodeships = voivodeshipsVal;
    }
}

export interface TaxOffice {
    id: number;
    name: string;
    address: string;
    voivodeshipId: number;
}

export interface Voivodeship {
    id: number;
    name: string;
}

export enum TaxStatus
{
    FormOptionA = 1,
    OptionA = 2,
    FormOptionB = 3,
    OptionB = 4,
    FormOptionC = 5,
    OptionC = 6,
}

export enum TaxType
{
    Pit2 = 1,
    MidClass = 2,
    TaxRate = 3,
    RevenueCost = 4,
}
