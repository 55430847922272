import VueI18n from 'vue-i18n';
import {i18n} from '@/plugins/i18n';
import {I18nProvider} from '@/shared/types/I18nProvider';

export function wellFormed(v: string, i18nProvider: I18nProvider = i18n): boolean | VueI18n.TranslateResult {
    if (v) {
        return /.+@.+/.test(v) || i18nProvider.t('emailShouldBeValid');
    } else {
        return true;
    }
}
