













import {Component, Vue, Prop} from 'vue-property-decorator';
import {FormMode} from '@/employee/employeeDetails/FormMode';

@Component
export default class FormSaveButtons extends Vue {
    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    valid!: boolean;

    @Prop(String)
    mode!: FormMode;
}
