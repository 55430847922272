export enum ConsentStatusIcon {
    NotSet = 'not_listed_location',
    Granted = 'thumb_up',
    NotGranted = 'thumb_down_alt',
}

export enum ConsentStatusColor {
    NotSet = 'warning',
    Granted = 'success',
    NotGranted = 'error',
}

export enum ConsentType {
    OfficeSafety,
    Rodo,
    Benefits
}

export type ConsentStatus = 'NotSet' | 'Granted' | 'NotGranted';

export interface Consent<TDateType = Date> {
    consent: ConsentStatus;
    date: TDateType;
    changedBy: string;
}
