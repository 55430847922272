

































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {changeConsent, getHistory} from '@/employee/imageConsent/store/endpoints';
import {Nullable} from '@/shared/types/Nullable';
import {ID} from '@/shared/store/AbstractEntity';
import {
    ConsentStatusIcon,
    ImageConsent,
    ConsentStatusColor,
    ImageConsentStatus,
} from './store/ImageConsent';
import {formatDateTime} from '@/shared/date/formatDate';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {HermesUser} from '@/employees/store/HermesUser';

const employees = namespace('employees');

@Component({
    components: {
        HmSimpleTable,
    },

    filters: {
        formatDateTime,
    },
})
export default class ImageConsentForm extends Mixins(ApiCall) {
    @Prop(Number)
    employeeId!: ID;

    consentHistory: Nullable<ImageConsent[]> = null;

    ConsentStatusIcon = ConsentStatusIcon;
    ConsentStatusColor = ConsentStatusColor;

    @employees.State('current') employee!: HermesUser;
    @employees.Mutation('updateCurrent') updateCurrentEmployee!: Function;

    get headers(): string[] {
        return [
            this.$t('consentDate') as string,
            this.$t('consentStatus') as string,
            this.$t('consentChangedBy') as string,
        ];
    }

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.consentHistory = await getHistory(this.employeeId);
        });
    }

    async changeConsent(consent: boolean): Promise<void> {
        await this.apiCall(async () => {
            await changeConsent(this.employeeId, consent);
            this.consentHistory = await getHistory(this.employeeId);
        });

        this.updateCurrentEmployee({
            imageConsent: consent ? 'Granted' : 'NotGranted',
        });
    }

    get currentConsent(): ImageConsentStatus {
        return this.employee.imageConsent;
    }
}
