import {api} from '@/shared/api';
import {ID} from '@/shared/store/AbstractEntity';
import { InsuredRelative } from './InsuredRelative';
import { ZusInformationResponse } from './ZusInformation';



export async function getMyInsuredRelatives(employeeId: ID): Promise<InsuredRelative[]> {
    const {data} = await api<InsuredRelative[]>({
        method: 'get',
        url: `Employees/${employeeId}/ZusInsuredRelatives`,
    });

    return data.map((entry) => {
        return {
            ...entry,
            dateOfBirth: new Date(entry.dateOfBirth),
        };
    });
}

export async function updateInsuredRelative(employeeId: ID, insuredRelativeId: ID, formData: FormData): Promise<InsuredRelative> {
    const {data} = await api<InsuredRelative>({
        method: 'patch',
        url: `Employees/${employeeId}/ZusInsuredRelatives/${insuredRelativeId}`,
        data: formData,
    });

    return data;
}

export async function addInsuredRelative(employeeId: ID, formData: FormData): Promise<InsuredRelative> {
    const {data} = await api<InsuredRelative>({
        method: 'post',
        url: `Employees/${employeeId}/ZusInsuredRelatives`,
        data: formData,
    });

    return data;
}

export async function deleteInsuredRelative(employeeId: ID, insuredRelativeId: ID): Promise<void> {
    await api<InsuredRelative>({
        method: 'delete',
        url: `Employees/${employeeId}/ZusInsuredRelatives/${insuredRelativeId}`,
    });

}

export async function getZusInformation(employeeId: ID): Promise<ZusInformationResponse> {
    const {data} = await api<ZusInformationResponse>({
        method: 'get',
        url: `Employees/zusInfo/${employeeId}`,
    });

    return data;
}

export async function updateZusInformation(employeeId: ID, formData: FormData): Promise<ZusInformationResponse> {
    const {data} = await api<ZusInformationResponse>({
        method: 'patch',
        url: `Employees/zusInfo/${employeeId}`,
        data: formData,
    });

    return data;
}

export async function postZusInformation(employeeId: ID, formData: FormData): Promise<ZusInformationResponse> {
    const {data} = await api<ZusInformationResponse>({
        method: 'post',
        url: `Employees/zusInfo/${employeeId}`,
        data: formData,
    });

    return data;
}


