export type ImageConsentStatus = 'NotSet' | 'Granted' | 'NotGranted';

export interface ImageConsent<TDateType = Date> {
    consent: ImageConsentStatus;
    date: TDateType;
    changedBy: string;
}

export enum ConsentStatusIcon {
    NotSet = 'not_listed_location',
    Granted = 'image',
    NotGranted = 'no_photography',
}

export enum ConsentStatusColor {
    NotSet = 'warning',
    Granted = 'success',
    NotGranted = 'error',
}
