
































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { InsuredRelative } from './store/InsuredRelative';
import { DictionaryItem } from './store/DictionaryItem';
import DatePicker from '@/shared/date/DatePicker.vue';
import ObjectVModel from '@/shared/mixins/ObjectVModel';

@Component({
    components: {
        DatePicker,
    },
})
export default class InsuredRelativeForm extends Mixins(ObjectVModel) {
    @Prop(Object)
    value!: InsuredRelative;

    get relationshipTypes(): DictionaryItem[] {
        return this.$store.state.dictionaries.zusRelationshipTypes;
    }

    get handicapLevels(): DictionaryItem[] {
        return this.$store.state.dictionaries.handicapLevels;
    }
}
