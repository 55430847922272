import {AbstractEntity} from '@/shared/store/AbstractEntity';

export interface EmergencyContact extends AbstractEntity {
    firstName: string;
    lastName: string;
    phone: string;
}

export type EmergencyContactAdd = Omit<EmergencyContact, 'id'>;

export function isValidEmergencyContact(value: EmergencyContact | EmergencyContactAdd): boolean {
    return !!value.phone && (value.firstName !== '' || value.lastName !== '');
}
