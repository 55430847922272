import {Component, Inject, Vue} from 'vue-property-decorator';
import {LocaleMessages} from 'vue-i18n';

export type MessageColor = 'success' | 'error' | 'info';

@Component({
})
export class InjectShowMessage extends Vue {
    @Inject()
    showMessage!: (message: string | LocaleMessages, color: MessageColor) => void;
}
