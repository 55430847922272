import {ImageConsent} from '@/employee/imageConsent/store/ImageConsent';
import {api} from '@/shared/api';
import {ID} from '@/shared/store/AbstractEntity';
import {forClient} from '@/shared/date/convertDate';

export async function getHistory(employeeId: ID): Promise<ImageConsent[]> {
    const result = await api<ImageConsent<string>[]>({
        method: 'get',
        url: `Employees/imageConsent/${employeeId}`,
    });

    return result.data.map((imageConsent) => {
        return {
            consent: imageConsent.consent,
            date: forClient(imageConsent.date),
            changedBy: imageConsent.changedBy,
        };
    });
}

export async function changeConsent(employeeId: ID, consent: boolean): Promise<null> {
    await api<ImageConsent<string>[]>({
        method: 'post',
        url: `Employees/imageConsent/${employeeId}`,
        headers: {
            'content-type': 'application/json',
        },
        data: JSON.stringify(consent),
    });

    return null;
}
