


























import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {MetaInfo} from 'vue-meta';

import {Nullable} from '@/shared/types/Nullable';
import EmployeeCard from '@/employee/employeeDetails/EmployeeCard.vue';
import {HermesUser, MeUser} from '@/employees/store/HermesUser';
import {setError, updateCurrent} from '@/shared/store/commonTypes';
import {FormMode} from '@/employee/employeeDetails/FormMode';
import {AjaxStateError} from '@/shared/store/AjaxState';
import AppScreen from '@/layout/AppScreen.vue';

const employees = namespace('employees');

@Component({
    components: {
        AppScreen,
        EmployeeCard,
    },

    metaInfo(this: EmployeeDetailsScreen): MetaInfo {
        return {
            titleTemplate: '%sHERMES',
            title: this.currentUser ? `${this.currentUser.fullName} — ` : '',
        };
    },
})
export default class EmployeeDetailsScreen extends Vue {
    @Prop()
    readonly id!: string;

    @employees.Action read!: Function;
    @employees.Action update!: Function;
    @employees.Action delete!: Function;
    @employees.State isFetching!: boolean;
    @employees.State error!: AjaxStateError;
    @employees.Getter validationErrors!: string[];
    @employees.Mutation [updateCurrent]: Function;
    @employees.Mutation [setError]: Function;

    @InjectReactive()
    readonly viewer!: MeUser;

    protected currentUser: Nullable<HermesUser> = null;
    protected success = false;

    get userId(): string | number {
        if (this.id === 'me') {
            return this.viewer.id;
        } else {
            return this.id;
        }
    }

    @Watch('userId')
    async onIdChange(newValue: string): Promise<void> {
        this.currentUser = await this.read(newValue);
    }

    async mounted(): Promise<void> {
        this.currentUser = await this.read(this.userId);
    }

    async onSave(userData: HermesUser): Promise<void> {
        await this.update(userData);

        if (!this.error) {
            this.success = true;

            setTimeout(() => {
                this.success = false;
            }, 3000);
        }
    }

    onInput(userData: HermesUser): void {
        this[updateCurrent](userData);
        this[setError](null);
    }

    async onDelete(): Promise<void> {
        await this.delete(this.currentUser);
        this.$router.push({name: 'employees'});
    }

    get formMode(): FormMode {
        if (this.currentUser) {
            if (this.viewer.isAdmin) {
                return 'admin';
            } else {
                if (this.currentUser.manager && this.currentUser.manager.id === this.viewer.id) {
                    return 'manager';
                } else if (this.currentUser.id === this.viewer.id) {
                    return 'self';
                } else {
                    return 'other';
                }
            }
        }

        return 'other';
    }
}
