


















































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getTaxesResult, setTaxStatus} from '@/employee/taxes/store/endpoints';
import {Nullable} from '@/shared/types/Nullable';
import {ID} from '@/shared/store/AbstractEntity';
import {HermesUser} from '@/employees/store/HermesUser';
import {TaxesResult, TaxStatus, TaxType} from '@/employee/taxes/store/Taxes';
import TaxOfficeForm from '@/employee/taxes/TaxOfficeForm.vue';

const employees = namespace('employees');

@Component({
    components: {
        TaxOfficeForm,
    },
})
export default class TaxesForm extends Mixins(ApiCall) {
    @Prop(Number)
    employeeId!: ID;

    taxesResult: Nullable<TaxesResult> = null;
    @employees.State('current') employee!: HermesUser;

    TaxType = TaxType;
    TaxStatus = TaxStatus;

    async mounted(): Promise<void> {
        await this.fetchData();
    }
    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.taxesResult = await getTaxesResult(this.employeeId);
        });
    }

    async setTaxStatus(taxType: TaxType, status: TaxStatus): Promise<void>{
        await this.apiCall(async () => {
            await setTaxStatus(this.employeeId, taxType, status);
            this.taxesResult = await getTaxesResult(this.employeeId);
        });
    }

    twoOptionsForms(status: TaxStatus): TaxStatus[] {
        if(status == TaxStatus.OptionA){
            return [TaxStatus.FormOptionB];
        }
        if(status == TaxStatus.OptionB){
            return [TaxStatus.FormOptionA];
        }
        return [];
    }

    threeOptionsForms(status: TaxStatus): TaxStatus[] {
        if(status == TaxStatus.OptionA){
            return [TaxStatus.FormOptionB, TaxStatus.FormOptionC];
        }
        if(status == TaxStatus.OptionB){
            return [TaxStatus.FormOptionA, TaxStatus.FormOptionC];
        }
        if(status == TaxStatus.OptionC){
            return [TaxStatus.FormOptionA, TaxStatus.FormOptionB];
        }
        return [];
    }
}
