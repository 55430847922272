import {SelfDevRequestAddModel, SelfDevelopmentData, SelfDevelopmentRequest} from '@/employee/selfDevelopment/store/SelfDevelopmentRequest';
import {ID} from '@/shared/store/AbstractEntity';
import {api} from '@/shared/api';

export async function getSelfDevData(id: ID): Promise<SelfDevelopmentData[]> {
    const {data} = await api<SelfDevelopmentData[]>({
        method: 'get',
        url: `Employees/selfDevelopment/${id}`,
    });

    for (const datum of data) {
        for (const request of datum.requests) {
            request.startDate = new Date(request.startDate);
            request.endDate = request.endDate ? new Date(request.endDate) : null;
        }
    }

    return data;
}

export async function sendSelfDevRequestForm(employeeId: ID, selfDevRequestForm: SelfDevRequestAddModel): Promise<SelfDevelopmentRequest> {
    const {data} = await api<SelfDevelopmentRequest>({
        method: 'post',
        url: `Employees/selfDevelopment/${employeeId}`,
        data: selfDevRequestForm,
    });

    return data;
}

export async function withdrawSelfDevRequest(employeeId: ID, requestId: ID): Promise<void> {
    await api<SelfDevelopmentData[]>({
        method: 'get',
        url: `Employees/selfDevelopment/${employeeId}/withdraw/${requestId}`,
    });
}
