


































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {ApiCall} from '@/shared/mixins/ApiCall';
import AppDialog from '@/shared/AppDialog.vue';
import {changeConsent, getHistory} from '@/employee/consents/store/endpoints';
import {Nullable} from '@/shared/types/Nullable';
import {ID} from '@/shared/store/AbstractEntity';
import {
    ConsentStatus,
    ConsentStatusIcon,
    ConsentStatusColor, ConsentType, Consent,
} from './store/Consent';
import {formatDateTime} from '@/shared/date/formatDate';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {HermesUser} from '@/employees/store/HermesUser';

const employees = namespace('employees');

@Component({
    components: {
        HmSimpleTable,
        AppDialog,
    },

    filters: {
        formatDateTime,
    },
})
export default class SingleConsent extends Mixins(ApiCall) {
    @Prop(Number)
    employeeId!: ID;

    @Prop(Number)
    consentType!: ConsentType;

    @Prop(Boolean)
    allowWithdraw!: boolean;

    @Prop(Boolean)
    allowHistory!: boolean;

    ConsentTypeEnum = ConsentType;

    consentHistory: Nullable<Consent[]> = null;

    ConsentStatusIcon = ConsentStatusIcon;
    ConsentStatusColor = ConsentStatusColor;
    showConsentHistory = false;

    @employees.State('current') employee!: HermesUser;
    @employees.Mutation('updateCurrent') updateCurrentEmployee!: Function;

    get headers(): string[] {
        return [
            this.$t('consentDate') as string,
            this.$t('consentStatus') as string,
            this.$t('consentChangedBy') as string,
        ];
    }

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.consentHistory = await getHistory(this.employeeId, this.consentType);
        });
    }

    async changeConsent(consent: boolean): Promise<void> {
        await this.apiCall(async () => {
            await changeConsent(this.employeeId, this.consentType, consent);
            this.consentHistory = await getHistory(this.employeeId, this.consentType);
        });

        switch (this.consentType){
        case ConsentType.OfficeSafety:
            this.updateCurrentEmployee({
                officeSafetyConsent: consent ? 'Granted' : 'NotGranted',
            });
            break;
        case ConsentType.Rodo:
            this.updateCurrentEmployee({
                rodoConsent: consent ? 'Granted' : 'NotGranted',
            });
            break;
        case ConsentType.Benefits:
            this.updateCurrentEmployee({
                benefitsConsent: consent ? 'Granted' : 'NotGranted',
            });
            break;
        }
    }

    get currentConsent(): ConsentStatus {
        switch (this.consentType){
        case ConsentType.OfficeSafety:
            return this.employee.officeSafetyConsent;
        case ConsentType.Rodo:
            return this.employee.rodoConsent;
        case ConsentType.Benefits:
            return this.employee.benefitsConsent;
        }

        return 'NotGranted';
    }
}
