












import {Component, Prop, Vue} from 'vue-property-decorator';
import {
    SelfDevelopmentRequest,
    SelfDevelopmentRequestStatus,
    SelfDevelopmentStatusIcon,
    SelfDevelopmentStatusColor,
} from '@/employee/selfDevelopment/store/SelfDevelopmentRequest';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;

@Component
export default class SelfDevelopmentRequestStatusIcon extends Vue {
    @Prop(Object)
    selfDevRequest!: SelfDevelopmentRequest;

    get color(): string {
        return SelfDevelopmentStatusColor[this.selfDevRequest.approvalStatus];
    }

    get icon(): string {
        return SelfDevelopmentStatusIcon[this.selfDevRequest.approvalStatus];
    }

    get tooltip(): string | LocaleMessage {
        return this.$t(`selfDevRequestStatus-${SelfDevelopmentRequestStatus[this.selfDevRequest.approvalStatus]}`);
    }
}
