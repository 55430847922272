






















































































































































































































































































import {Component, Emit, Prop, Mixins} from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

import { wellFormed } from '@/shared/emailRules';
import AddressSubForm from '@/shared/AddressSubForm.vue';
import DatePicker from '@/shared/date/DatePicker.vue';
import IdDocumentTypeWidget from '@/dictionaries/IdDocumentTypes/IdDocumentTypeWidget.vue';
import {HermesUser} from '@/employees/store/HermesUser.js';
import ContractTypeWidget from '@/dictionaries/contractTypes/ContractTypeWidget.vue';
import ColWidth from '@/shared/mixins/ColWidth';
import HmCol from '@/shared/HmCol.vue';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import {FormMode} from '@/employee/employeeDetails/FormMode';
import ContractHistory from '@/employee/contractHistory/ContractHistory.vue';
import GenderDropdown from '@/shared/GenderDropdown.vue';
import BenefitsView from '@/employee/benefits/BenefitsView.vue';
import SelfDevelopmentRequestsView from '@/employee/selfDevelopment/SelfDevelopmentRequestsView.vue';
import FormSaveButtons from '@/employee/employeeDetails/FormSaveButtons.vue';
import ImageConsent from '@/employee/imageConsent/ImageConsentForm.vue';
import EmergencyContactScreen from '@/employee/emergencyContact/EmergencyContactScreen.vue';
import EmployeeZusScreen from '@/employee/employeeZus/EmployeeZusScreen.vue';
import TaxesForm from '@/employee/taxes/TaxesForm.vue';
import ConsentsForm from '@/employee/consents/ConsentsForm.vue';

type HermesUserKeys = (keyof HermesUser)[];

@Component({
    components: {
        ConsentsForm,
        TaxesForm,
        EmergencyContactScreen,
        ImageConsent,
        FormSaveButtons,
        SelfDevelopmentRequestsView,
        BenefitsView,
        GenderDropdown,
        ContractHistory,
        ContractTypeWidget,
        IdDocumentTypeWidget,
        DatePicker,
        AddressSubForm,
        HmCol,
        EmployeeZusScreen,
    },
    directives: {
        mask,
    },
})
export default class EmployeeEditForm extends Mixins(ColWidth, InputAttrs) {
    @Prop(Object)
    protected readonly value!: HermesUser;

    @Prop({type: String, default: 'admin'})
    protected readonly mode!: FormMode;

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    valid!: boolean;

    protected emailRules = [wellFormed];
    protected tab = 0;

    protected readonly bankAccountMask = '## #### #### #### #### #### ####';

    protected alwaysReadOnly: HermesUserKeys = [
        'firstName', 'lastName', 'manager', 'organizationalUnitName',
    ];

    protected otherView: HermesUserKeys = [
        'firstName', 'fullName', 'lastName',
        'adAccount', 'email', 'id', 'location', 'manager', 'organizationalUnitName', 'jobTitle', 'workPhone',
    ];
    protected managerView: HermesUserKeys = [
        ...this.otherView,
        'personalCellPhone', 'personalEmail',
        'employmentType', 'contractType', 'jiraFabresUsername', 'komplettLogin', 'rambaseLogin',
    ];
    protected selfView: HermesUserKeys = [
        ...this.managerView,
        'celebrateBirthDay', 'citizenship', 'correspondenceAddress', 'workFromHomeAddress', 'workFromHomeAddessFromDate', 'dateOfBirth', 'documentIdNumber', 'documentTypeId', 'gender', 'livingAddress', 'pesel', 'placeOfBirth', 'registeredAddress',
        'bankAccountNumber', 'companyName', 'companyVatNumber', 'companyAddress', 'isTerminated', 'medicalValidityDate', 'symbolAccountant', 'timeSheetRequired', 'middleName', 'maidenName','motherName','fatherName',
    ];
    protected adminView: HermesUserKeys = [...this.selfView];

    protected selfEdit: HermesUserKeys = [
        'celebrateBirthDay',
        'correspondenceAddress', 'workFromHomeAddress', 'workFromHomeAddessFromDate', 'livingAddress', 'personalCellPhone', 'personalEmail', 'registeredAddress',
        'bankAccountNumber', 'companyName', 'companyVatNumber', 'companyAddress', 'middleName', 'maidenName','motherName','fatherName',
    ];
    protected adminEdit: HermesUserKeys = [
        ...this.selfEdit,
        'citizenship', 'dateOfBirth', 'documentIdNumber', 'documentTypeId', 'gender', 'pesel', 'placeOfBirth',
        'jiraFabresUsername', 'komplettLogin', 'rambaseLogin', 'isTerminated', 'medicalValidityDate', 'symbolAccountant', 'timeSheetRequired',
    ];

    protected boolFields: HermesUserKeys = [
        'timeSheetRequired', 'isTerminated', 'celebrateBirthDay',
    ];

    protected contractorOnly: HermesUserKeys = [
        'companyName', 'companyVatNumber', 'companyAddress',
    ];

    protected employeeOnly: HermesUserKeys = [
        'medicalValidityDate', 'symbolAccountant',
    ];

    protected get selfOrAdmin(): boolean {
        return this.mode === 'self' || this.mode === 'admin';
    }

    protected get shouldShowUopInfoTabs(): boolean {
        return this.value.employmentType !== 'Contractor';
    }

    onInput(field: keyof HermesUser, value: HermesUser[keyof HermesUser]): void {
        const newModel: HermesUser = {
            ...this.value,
            [field]: value,
        };

        this.$emit('input', newModel);
    }

    isFieldHidden(name: keyof HermesUser): boolean {
        if (this.contractorOnly.includes(name) && this.value.employmentType !== 'Contractor') {
            return true;
        }

        if (this.employeeOnly.includes(name) && this.value.employmentType === 'Contractor') {
            return true;
        }

        if (this.mode === 'admin') {
            return !this.adminView.includes(name);
        } else if (this.mode === 'self') {
            return !this.selfView.includes(name);
        } else if (this.mode === 'manager') {
            return !this.managerView.includes(name);
        } else if (this.mode === 'other') {
            return !this.otherView.includes(name);
        }

        return true;
    }

    isFieldDisabled(name: keyof HermesUser): boolean {
        if (this.alwaysReadOnly.includes(name)) {
            return true;
        }

        if (this.mode === 'other' || this.mode === 'manager') {
            return true;
        } else if (this.mode === 'self') {
            return !this.selfEdit.includes(name);
        } else if (this.mode === 'admin') {
            return !this.adminEdit.includes(name);
        }

        return true;
    }

    fieldAttrs(name: keyof HermesUser): object {
        // vuetify incorrectly displays checkboxes if they have :value instead of :input-value
        const isBoolField = this.boolFields.includes(name);
        const valueField = isBoolField ? 'inputValue' : 'value';

        const attrs = {
            ...this.inputAttrs,
            disabled: this.isFieldDisabled(name),
            [valueField]: this.value[name],
            label: this.$t(name),
            name,
        };

        if (isBoolField) {
            attrs.trueValue = true;
            attrs.falseValue = false;
        }

        return attrs;
    }

    @Emit('input')
    copyLivingAddressToRegistered(): HermesUser {
        const newModel: HermesUser = {
            ...this.value,
            registeredAddress: this.value.livingAddress,
        };

        return newModel;
    }

    @Emit('input')
    copyRegisteredAddressToCorrespondence(): HermesUser {
        const newModel: HermesUser = {
            ...this.value,
            correspondenceAddress: this.value.registeredAddress,
        };

        return newModel;
    }

    @Emit('input')
    copyRegisteredAddressToWorkFromHomeAddress(): HermesUser {
        const newModel: HermesUser = {
            ...this.value,
            workFromHomeAddress: this.value.registeredAddress,
        };

        return newModel;
    }
}
