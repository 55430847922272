



























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {ID} from '@/shared/store/AbstractEntity';
import {
    createEmergencyContact,
    deleteEmergencyContact,
    listEmergencyContacts,
    updateEmergencyContact,
} from '@/employee/emergencyContact/store/endpoints';
import EmergencyContactList from '@/employee/emergencyContact/EmergencyContactList.vue';
import AppDialog from '@/shared/AppDialog.vue';
import EmergencyContactForm from '@/employee/emergencyContact/EmergencyContactForm.vue';
import {
    EmergencyContact,
    EmergencyContactAdd, isValidEmergencyContact,
} from '@/employee/emergencyContact/store/EmergencyContact';
import {Nullable} from '@/shared/types/Nullable';

@Component({
    components: {
        EmergencyContactForm,
        AppDialog,
        EmergencyContactList,
        HmSimpleTable,
    },
})
export default class EmergencyContactScreen extends Mixins(ApiCall) {
    @Prop(Number)
    employeeId!: ID;

    @Prop(Boolean)
    readOnly!: boolean;

    emergencyContacts: Array<EmergencyContact> = [];

    contactToDelete: Nullable<EmergencyContact> = null;
    contactToEdit: Nullable<EmergencyContact | EmergencyContactAdd> = null;

    async mounted(): Promise<void> {
        await this.fetchList();
    }

    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            this.emergencyContacts = await listEmergencyContacts(this.employeeId);
        });
    }

    onDelete(emergencyContact: EmergencyContact): void {
        this.contactToDelete = emergencyContact;
    }

    async onDeleteConfirm(): Promise<void> {
        const index = this.emergencyContacts.findIndex((ec) => ec.id === this.contactToDelete!.id);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await deleteEmergencyContact(this.employeeId, this.contactToDelete!);
        });

        if (!this.error) {
            this.emergencyContacts.splice(index, 1);
        }

        this.contactToDelete = null;

        await this.fetchList();
    }

    onEdit(emergencyContact: EmergencyContact): void {
        this.contactToEdit = emergencyContact;
    }

    async onSaveConfirm(contactToSave: EmergencyContact): Promise<void> {
        const index = this.emergencyContacts.findIndex((ec) => ec.id === contactToSave.id);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await updateEmergencyContact(this.employeeId, contactToSave);
        });

        if (!this.error) {
            Object.assign(this.emergencyContacts[index], contactToSave);
        }

        this.contactToEdit = null;

        await this.fetchList();
    }

    async onCreateConfirm(contactToAdd: EmergencyContactAdd): Promise<void> {
        await this.apiCall(async () => {
            await createEmergencyContact(this.employeeId, contactToAdd);
        });

        this.contactToEdit = null;

        await this.fetchList();
    }

    get isEditedContactValid(): boolean {
        if (!this.contactToEdit) {
            return false;
        }

        return isValidEmergencyContact(this.contactToEdit);
    }

    get deleteContactConfirmBody(): string {
        if (!this.contactToDelete) {
            return '';
        }

        return this.$t('emergencyContactsConfirmDeleteBody', this.contactToDelete) as string;
    }
}
