import { AbstractEntity } from '@/shared/store/AbstractEntity';

export interface InsuredRelative extends AbstractEntity{
    firstName: string;
    lastName: string;
    pesel: string;
    dateOfBirth: Date;
    zusRelationshipTypeId: number;
    zusHandicapLevelId: number;
    sameHousehold: boolean;
    employeeId: number;
}
export type InsuredRelativeAddRequest = Omit<InsuredRelativeEditRequest, 'id'>;

export interface InsuredRelativeEditRequest extends AbstractEntity{
    firstName: string;
    lastName: string;
    pesel: string;
    dateOfBirth: string;
    zusRelationshipTypeId: number;
    zusHandicapLevelId: number;
    sameHousehold: boolean;
    employeeId: number;
}

export function isValidInsuredRelative(value: InsuredRelative): boolean {
    return !!value.firstName && !!value.lastName && !!value.pesel && !!value.dateOfBirth
    && value.zusRelationshipTypeId !== undefined
    && value.zusHandicapLevelId !== undefined;
}