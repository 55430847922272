













import { Component, Mixins, Prop } from 'vue-property-decorator';
import { InputAttrs } from '@/shared/mixins/InputAttrs';
import { FileExtension } from '@/dictionaries/fileExtensions/store';
import { getFileExtension } from '@/shared/getFileExtension';

@Component
export default class FileInput extends Mixins(InputAttrs) {
    @Prop(Array)
    value!: File[]

    @Prop(Boolean)
    required!: boolean

    @Prop(Boolean)
    multiple!: boolean

    @Prop(String)
    label!: string

    get allowedExtensions(): FileExtension[] {
        return this.$store.state.dictionaries.fileExtensions;
    }

    get formatAllowedExtension(): string {
        const extensions = this.allowedExtensions?.map(x => x.suffix);
        return extensions?.length ? `${this.$t('acceptedExtensions')}: ${extensions.join(', ')}` : '';
    }

    onChange(value: File[]): void{
        this.$emit('input', value);
    }

    fieldRequired(value: File[]): boolean | string{
        if (this.required && (!value || value.length === 0)) {
            return this.$t('requiredField') as string;
        }
        return true;
    }

    validExtension(value: File[]): boolean | string{
        if (value && value.length){
            for (const file of value) {
                const extension = getFileExtension(file?.name);
                if (value && !this.allowedExtensions.some(x => x.suffix.toUpperCase() === extension.toUpperCase())){
                    return `${this.$t('invalidExtension') as string}. ${this.formatAllowedExtension}`;
                }
            }
        }

        return true;
    }
}
