import { render, staticRenderFns } from "./EmergencyContactScreen.vue?vue&type=template&id=61dbae61&scoped=true&"
import script from "./EmergencyContactScreen.vue?vue&type=script&lang=ts&"
export * from "./EmergencyContactScreen.vue?vue&type=script&lang=ts&"
import style0 from "./EmergencyContactScreen.vue?vue&type=style&index=0&id=61dbae61&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61dbae61",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VBtn,VIcon,VProgressLinear})
