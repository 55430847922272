






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {Nullable} from './types/Nullable.js';
import HmCol from '@/shared/HmCol.vue';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import {Address} from '@/shared/types/Address';

@Component({
    components: {
        HmCol,
    },
})
export default class AddressSubForm extends Mixins(InputAttrs) {
    @Prop(Object)
    readonly value!: Nullable<Address>;

    @Prop(Boolean)
    disabled!: boolean;

    @Prop(String)
    subheader!: string;

    onInput(field: string, value: string): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }
}
