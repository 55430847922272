








import {Component, Prop, Vue} from 'vue-property-decorator';
import {EmergencyContact} from './store/EmergencyContact';

@Component
export default class EmergencyContactForm extends Vue {
    @Prop(Object)
    value!: EmergencyContact;

    onInput(field: keyof EmergencyContact, value: EmergencyContact[keyof EmergencyContact]): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }
}
