import { render, staticRenderFns } from "./EmployeeDetailsScreen.vue?vue&type=template&id=bed7a3ec&scoped=true&"
import script from "./EmployeeDetailsScreen.vue?vue&type=script&lang=ts&"
export * from "./EmployeeDetailsScreen.vue?vue&type=script&lang=ts&"
import style0 from "./EmployeeDetailsScreen.vue?vue&type=style&index=0&id=bed7a3ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed7a3ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VProgressLinear,VSnackbar})
