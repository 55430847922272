import axios from 'axios';
import {ID} from '@/shared/store/AbstractEntity';
import {api} from '@/shared/api';
import {loadRuntimeConfig} from '@/shared/loadRuntimeConfig';
import {BenefitEntry} from '@/employee/benefits/store/BenefitEntry';
import {BenefitsRequest} from '@/employee/benefits/store/BenefitsRequest';

export async function getBenefits(id: ID): Promise<BenefitEntry[]> {
    const {data} = await api<BenefitEntry<string>[]>({
        method: 'get',
        url: `Employees/benefits/${id}`,
    });

    return data.map((entry) => {
        return {
            ...entry,
            startDate: entry.startDate ? new Date(entry.startDate) : null,
            endDate: entry.endDate ? new Date(entry.endDate) : null,
        };
    });
}

export async function sendBenefitsRequestForm(employeeId: ID, BenefitsRequestForm: BenefitsRequest): Promise<number> {

    const config = await loadRuntimeConfig();
    const urlBenefits = config.benefitsReport;

    BenefitsRequestForm.employeeID = employeeId;

    if (BenefitsRequestForm.endDate == null) {
        BenefitsRequestForm.endDate = '';
    }

    const response = await axios.request<void>({
        method: 'post',
        url: urlBenefits,
        data: BenefitsRequestForm,
    });

    return response.status;
}


