














































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { NfzUnit } from '@/dictionaries/nfzUnits/store';
import { ApiCall } from '@/shared/mixins/ApiCall';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import { getZusInformation, postZusInformation, updateZusInformation } from './store/endpoints';
import { Nullable } from '@/shared/types/Nullable';
import { ZusInfoHandicapLevel, ZusInformationRequest, ZusInformationResponse } from './store/ZusInformation';
import { Validatable } from '@/shared/types/Validatable';
import { InjectShowMessage } from '@/shared/mixins/InjectShowMessage';
import { ID } from '@/shared/store/AbstractEntity';
import HmCol from '@/shared/HmCol.vue';
import FileInput from '@/shared/FileInput.vue';
import { DictionaryItem } from './store/DictionaryItem';

@Component({
    components: {
        HmCol, FileInput,
    },
})

export default class MyZusInformation extends Mixins(ApiCall, InputAttrs, InjectShowMessage) {

    @Prop(Number)
    employeeId!: ID;

    protected valid = false;
    protected userHasZusInfo = false;
    protected nfzCode = '';
    protected isHandicapped = false;
    protected isRetired = false;
    protected handicapLevel: ZusInfoHandicapLevel = 0;
    protected statementFile: Nullable<File[]> = null;
    protected statementFileUri = '';
    protected statementFileName = '';

    async mounted(): Promise<void> {
        await this.fetchData();
    }
    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            const response = await getZusInformation(this.employeeId);
            this.mapResponseToData(response);
            this.userHasZusInfo = !!response;
        });
    }

    mandatoryFieldRule(value: string): boolean | string  {
        return !!value || this.$t('requiredField') as string;
    }

    handicapFieldRequired(value: string): boolean | string{
        if (this.isHandicapped && !value){
            this.valid = false;
            return this.$t('requiredField') as string;
        }
        return true;
    }

    get isFileRequired(): boolean {
        if (this.isHandicapped && !this.statementFileName){
            return true;
        }
        return false;
    }

    clearHandicapFields(): void {
        if(!this.isHandicapped){
            this.handicapLevel = 0;
            this.statementFile = null;
        }
    }

    async onSubmit(): Promise<void> {
        (this.$refs.form as Vue & Validatable).validate();

        if(!this.valid || (this.isFileRequired && !this.statementFile)) {
            return;
        }
        const request: Partial<ZusInformationRequest> = {
            nfzUnitCode: this.nfzCode,
            retirementGranted: this.isRetired,
            isHandicapped: this.isHandicapped,
            handicapLevelId: this.handicapLevel,
        };

        const formData = new FormData();

        if (this.statementFile && this.statementFile?.length > 0 && this.statementFile[0].size){
            formData.append('StatementFile', this.statementFile[0]);
        }
        for (const [key, value] of Object.entries(request)) {
            formData.append(key, value?.toString() || '');
        }
        const toCall = this.userHasZusInfo ? updateZusInformation : postZusInformation;
        await this.apiCall(async () => {
            const response = await toCall(this.employeeId, formData);
            this.mapResponseToData(response);
            this.userHasZusInfo = true;
        });
        if (!this.error) {
            this.showMessage(this.$t('savedSuccessfully'), 'success');
        }
    }

    mapResponseToData(response: ZusInformationResponse): void {
        this.nfzCode = response.nfzUnitCode;
        this.isHandicapped = response.isHandicapped;
        this.isRetired = response.retirementGranted;
        this.handicapLevel = response.handicapLevelId;
        this.statementFileName = response.statementReadableFileName;
        this.statementFileUri = response.statementFileUri;
    }

    get nfzUnits(): NfzUnit {
        return this.$store.state.dictionaries.nfzUnits;
    }

    get handicapLevels(): DictionaryItem[] {
        const handicapLevelItems: DictionaryItem[] = this.$store.state.dictionaries.handicapLevels;
        const handicapLevels: DictionaryItem[] =  [];

        handicapLevelItems.forEach(x => {
            const handicapLevel = ZusInfoHandicapLevel[x.id];
            if (handicapLevel){
                handicapLevels.push({
                    id: x.id,
                    name: handicapLevel,
                });
            }
        });
        return handicapLevels;
    }
}
