import {AbstractEntity} from '@/shared/store/AbstractEntity';
import {Nullable} from '@/shared/types/Nullable';

export enum SelfDevelopmentRequestStatus {
    Pending,
    Approved,
    Declined,
    PreApproved,
    Withdrawn,
    Closed
}

export enum EventTypes {
    Conference,
    Webinar,
    CourseWorkshopTraining,
    CoachingSession,
    Studies,
    Certificate,
    LanguageLessons,
    ElearningPlatform,
    SelfDevHours,
}

export const SelfDevelopmentStatusIcon = {
    [SelfDevelopmentRequestStatus.Pending]: 'watch_later',
    [SelfDevelopmentRequestStatus.Approved]: 'check_circle_outline',
    [SelfDevelopmentRequestStatus.Declined]: 'error_outline',
    [SelfDevelopmentRequestStatus.PreApproved]: 'check',
    [SelfDevelopmentRequestStatus.Withdrawn]: 'warning',
    [SelfDevelopmentRequestStatus.Closed]: 'lock',
};

export const SelfDevelopmentStatusColor = {
    [SelfDevelopmentRequestStatus.Pending]: 'warning',
    [SelfDevelopmentRequestStatus.Approved]: 'success',
    [SelfDevelopmentRequestStatus.Declined]: 'error',
    [SelfDevelopmentRequestStatus.PreApproved]: 'success',
    [SelfDevelopmentRequestStatus.Withdrawn]: 'warning',
    [SelfDevelopmentRequestStatus.Closed]: 'success',
};

export const eventTypes: string[] = ['eventTypesConference', 'eventTypesWebinar', 'eventTypesCourseWorkshopTraining', 'eventTypesCoachingSession', 'eventTypesStudies', 'eventTypesCertificate', 'eventTypesLanguageLessons', 'eventTypeseLearningPlatform', 'eventTypesSelfDevelopmentHours'];
export const locationOptions: string[] = ['selfDevLocationStationary', 'selfDevLocationOnline'];
export const typesOfSkills: string[] = ['selfDevSoftSkills', 'selfDevHardSkills'];

export interface SelfDevelopmentRequest<TDateType = Date> extends AbstractEntity {
    eventName: string;
    eventType?: string;
    eventOrganizerName?: string;
    eventOrganizerWebsite?: string;
    eventOrganizerAddress?: string;
    typeOfSkillsDeveloped?: string;
    eventDuration?: string;
    locationType: string;
    stationaryLocation?: string;
    startDate: TDateType;
    endDate: Nullable<TDateType>;
    cost: number;
    realCost: Nullable<number>;
    costBreakdown: string;
    goal: string;
    approvalStatus: SelfDevelopmentRequestStatus;
    declineReason: Nullable<string>;
    canWithdraw: boolean;
}

export type SelfDevRequestAddModel = Omit<SelfDevelopmentRequest, 'realCost' | 'approvalStatus' | 'declineReason' | 'canWithdraw' | 'id'>;

export interface SelfDevelopmentData {
    year: number;
    budget: number;
    budgetUsed: number;
    requests: SelfDevelopmentRequest[];
}
