
























import { Component, Vue, Prop } from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import { TranslateResult } from 'vue-i18n';
import { dictionaryArrayToObject, DictionaryItem } from './store/DictionaryItem';
import {formatDate} from '@/shared/date/formatDate';
import { Dict } from '@/shared/types/Dict';
import { InsuredRelative } from './store/InsuredRelative';

@Component({
    components: {
        HmSimpleTable,
    },
    filters: {
        formatDate,
    },
})
export default class InsuredRelativesList extends Vue{

    @Prop(Array)
    list!: InsuredRelative[];

    @Prop(Boolean)
    isFetching!: boolean;

    get relationshipTypes(): Dict<string>{
        return dictionaryArrayToObject(this.$store.state.dictionaries.zusRelationshipTypes as DictionaryItem[]);
    }

    get handicapLevels(): Dict<string>{
        return dictionaryArrayToObject(this.$store.state.dictionaries.handicapLevels as DictionaryItem[]);
    }

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('firstName'),
            this.$t('lastName'),
            this.$t('relationshipType'),
            this.$t('pesel'),
            this.$t('dateOfBirth'),
            this.$t('handicapLevel'),
            this.$t('sameHouseholdShort'),
            '',
        ];
    }

    getRelationshipTypeDisplayName(typeId: number): string {
        return this.relationshipTypes?.[typeId] ?? '';
    }

    getHandicapLevelDisplayName(typeId: number): string {
        return this.handicapLevels?.[typeId] ?? '';
    }
}
