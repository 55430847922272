
























































































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {SelfDevelopmentData, SelfDevelopmentRequest, SelfDevRequestAddModel, eventTypes} from '@/employee/selfDevelopment/store/SelfDevelopmentRequest';
import {getSelfDevData, sendSelfDevRequestForm, withdrawSelfDevRequest} from '@/employee/selfDevelopment/store/endpoints';
import {formatDate} from '@/shared/date/formatDate';
import SelfDevelopmentRequestStatusIcon from '@/employee/selfDevelopment/SelfDevelopmentRequestStatusIcon.vue';
import {Nullable} from '@/shared/types/Nullable';
import SelfDevelopmentCurrentYear from './SelfDevelopmentCurrentYear.vue';
import SelfDevelopmentRequestForm from './SelfDevelopmentRequestForm.vue';
import HmCol from '@/shared/HmCol.vue';
import {formatCurrency} from '@/shared/formatCurrency';
import {ID} from '@/shared/store/AbstractEntity';
import { TableHeader } from '@/shared/types/TableHeader';
import { DataTableProps } from '@/shared/mixins/DataTableProps';
import AppDialog from '@/shared/AppDialog.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
    components: {
        HmCol,
        SelfDevelopmentCurrentYear,
        SelfDevelopmentRequestStatusIcon,
        HmSimpleTable,
        AppDialog,
        SelfDevelopmentRequestForm,
    },
    filters: {
        formatDate,
        formatCurrency,
    },
})
export default class SelfDevelopmentRequestsView extends Mixins(ApiCall, DataTableProps ) {
    @Prop(Number)
    id!: number;

    selfDevData: SelfDevelopmentData[] = [];

    selectedYear: Nullable<number> = null;

    modalOpen = false;

    expanded = [];

    get headers(): TableHeader<SelfDevelopmentRequest>[] {
        return [
            {
                text: '',
                value: 'id',
                width: '5%',
            },
            {
                text: this.$t('selfDevEvent') as string,
                value: 'eventName',
            },
            {
                text: this.$t('selfDevStartDate') as string,
                value: 'startDate',
                width: '15%',
            },
            {
                text: this.$t('selfDevEndDate') as string,
                value: 'endDate',
                width: '15%',
            },
            {
                text: this.$t('selfDevCost') as string,
                value: 'cost',
                width: '10%',
            },
            {
                text: this.$t('selfDevRealCost') as string,
                value: 'realCost',
                width: '10%',
            },
            {
                text: this.$t('selfDevApprovalStatus') as string,
                value: 'approvalStatus',
                width: '10%',
                align: 'center',
            },
            {
                text: '',
                value: 'canWithdraw',
                width: '5%',
            },
        ];
    }

    formatEventTypes(input?: string): string {
        if (!input) {
            return '';
        }

        return input.split(',').map(type => {
            const typeTrimmed = type.trim();
            return eventTypes.includes(typeTrimmed) ? this.$t(typeTrimmed) : typeTrimmed;
        }).join(', ');
    }

    formatLocation(item: SelfDevelopmentRequest): string | TranslateResult {
        return item.locationType == 'selfDevLocationStationary' && item.stationaryLocation ? `${this.$t(item.locationType)} - ${item.stationaryLocation}` : this.$t(item.locationType);
    }

    async submitForm(requestForm: SelfDevRequestAddModel): Promise<void> {
        await this.apiCall(async () => {
            await sendSelfDevRequestForm(this.id, requestForm);
        });

        this.modalOpen = false;
        await this.fetchData();
    }

    async mounted(): Promise<void> {
        await this.fetchData();
        this.selectedYear = new Date().getFullYear();
    }

    async fetchData(): Promise<void> {
        await this.apiCall(async () => {
            this.selfDevData = await getSelfDevData(this.id);
        });
    }

    async withdraw(requestId: ID): Promise<void> {
        await this.apiCall(async () => {
            await withdrawSelfDevRequest(this.id, requestId);
        });

        await this.fetchData();
    }

    get years(): number[] {
        return this.selfDevData.map(sdd => sdd.year);
    }

    get selectedYearData(): Nullable<SelfDevelopmentData> {
        const data = this.selfDevData.find(sdd => sdd.year === this.selectedYear);
        return data || null;
    }
}
