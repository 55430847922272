











































import {Component, Prop, Vue} from 'vue-property-decorator';

import DistinctCard from '@/shared/DistinctCard.vue';
import ContactEmployeeBtn from '@/employee/employeeDetails/ContactEmployeeBtn.vue';
import EmployeeEditForm from '@/employee/employeeDetails/EmployeeEditForm.vue';
import {HermesUser} from '@/employees/store/HermesUser';
import {FormMode} from '@/employee/employeeDetails/FormMode';
import AppDialog from '@/shared/AppDialog.vue';
import {Validatable} from '@/shared/types/Validatable';
import FormSaveButtons from '@/employee/employeeDetails/FormSaveButtons.vue';

@Component({
    components: {
        FormSaveButtons,
        AppDialog,
        EmployeeEditForm,
        ContactEmployeeBtn,
        DistinctCard,
    },
})
export default class EmployeeCard extends Vue {
    @Prop(Object)
    readonly user!: HermesUser;

    @Prop(Boolean)
    readonly isFetching!: boolean;

    @Prop(String)
    readonly mode!: FormMode;

    mounted(): void {
        (this.$refs.form as Vue & Validatable).validate();
    }

    valid = false;
    showDeleteConfirmation = false;

    onConfirmDelete(): void {
        this.$emit('delete');
        this.showDeleteConfirmation = false;
    }
}
